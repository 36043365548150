<template>
  <div>
    <FormLoader v-if="isLoading" />
    <company-form
      v-else
      v-on:delete-company="deleteCompany"
      :showDeleteButton="true"
      v-on:submit="onSubmit"
      :isSubmitting="isSubmitting"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions, mapGetters } from "vuex";
import Api from "@/services/api";
import CompanyForm from "@/components/forms/CompanyForm.vue";
import FormLoader from "@/components/forms/FormLoader.vue";

export default {
  name: "EditCompany",
  components: { CompanyForm, FormLoader },
  data() {
    return {
      isLoading: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters({
      isMerchant: "isMerchant"
    }),
    selectedCompanyId() {
      return this.$route.params.companyId;
    }
  },
  mounted() {
    const endRoute = this.isMerchant ? "ROUTES.BRANCHES" : "ROUTES.COMPANIES";

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t(endRoute) },
      { title: this.$t("MENU.EDIT") }
    ]);
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelectedCompany();
    next();
  },
  created() {
    this.fetchCompany();
  },
  methods: {
    ...mapActions({
      clearSelectedCompany: "company/CLEAR_SELECTED_COMPANY",
      toggleCompanyActiveStatus: "company/TOGGLE_COMPANY_ACTIVE_STATUS"
    }),
    async fetchCompany() {
      this.isLoading = true;
      try {
        const getCompany = await this.$store.dispatch(
          "company/GET_COMPANY",
          this.selectedCompanyId
        );
        const { representative, ...rest } = getCompany.data;

        this.$store.commit("company/SET_SELECTED_COMPANY", {
          ...rest,
          representativeID: representative?.id || null
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
        this.$router.replace({ name: "company-companies" });
      }
    },
    onSubmit(company) {
      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage =
        this.$t("COMPANY.COMPANY") + this.$t("FORM.UPDATED");
      const errorMessage = this.$t("FORM.ERROR");
      this.isSubmitting = true;
      Api.put(`companies/${this.selectedCompanyId}`, company)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-companies" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    deleteCompany({ id, isActive }) {
      this.toggleCompanyActiveStatus({ companyId: id, isActive })
        .then(() => {
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-companies" });
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
  }
};
</script>
