<template>
  <div class="card" style="opacity: 0.8;">
    <div class="card-body">
      <p class="font-weight-bold offset-1 text-uppercase bg-secondary w-25">
        &nbsp;
      </p>
      <div
        class="form-group row"
        v-for="item in 6"
        :key="`template-form-loader-${item}`"
      >
        <label
          class="col-form-label text-right col-lg-3 col-sm-12 bg-secondary"
        >
          &nbsp;
        </label>
        <div class="col-lg-6 col-md-9 col-sm-12">
          <p class="form-control bg-secondary">
            &nbsp;
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormLoader"
};
</script>
