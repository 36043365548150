`<template>
  <div class="container">
    <CompanyDetails v-if="showCompanyDetails" />
    <div class="card card-custom example example-compact" v-else>
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <p class="font-weight-bold offset-1 text-uppercase">
            {{ $t("COMMON.general") }}
          </p>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("COMPANY.TITLE") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.title.$model"
                :state="validateState('title')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.title.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.EMAIL") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-help"
              />
              <small v-if="isNew" id="email-help" class="form-text text-muted">
                {{ $t("COMPANY.MAIL_INFO_MESSAGE") }}
              </small>
              <div class="validation error" v-if="$v.form.email.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.PHONE") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder="5XXXXXXXXX"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
              />
              <template v-if="$v.form.phone.$error">
                <div class="validation error" v-if="!$v.form.phone.required">
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div class="validation error" v-if="!$v.form.phone.numeric">
                  {{ $t("VALIDATION.NUMERIC") }}
                </div>
                <div class="validation error" v-if="!$v.form.phone.maxLength">
                  {{
                    $t("VALIDATION.MAX_LENGTH", {
                      num: $v.form.phone.$params.maxLength.max
                    })
                  }}
                </div>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_NUMBER")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxNumber.$model"
                :state="validateState('taxNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.taxNumber.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_ADMINISTRATION")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxAdministration.$model"
                :state="validateState('taxAdministration')"
                aria-describedby="input-1-live-feedback"
              />
              <!-- <div class="validation error" v-if="$v.form.taxAdministration.$error">
                {{ $t('AUTH.VALIDATION.REQUIRED_FIELD') }}
              </div> -->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_CITY")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxCity.$model"
                :state="validateState('taxCity')"
                aria-describedby="input-1-live-feedback"
              />
              <!-- <div class="validation error" v-if="$v.form.taxCity.$error">
                {{ $t('AUTH.VALIDATION.REQUIRED_FIELD') }}
              </div> -->
            </div>
          </div>

          <div class="form-group row" v-if="showCommissionInput">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                id="kt_money_input"
                v-model.number="$v.form.commission.$model"
                :state="validateState('commission')"
                aria-describedby="input-1-live-feedback"
              />
              <template v-if="$v.form.commission.$error">
                <div
                  class="validation error"
                  v-if="!$v.form.commission.numeric"
                >
                  {{ $t("VALIDATION.ONLY_INTEGERS") }}
                </div>
                <div
                  class="validation error"
                  v-if="!$v.form.commission.minValue"
                >
                  {{
                    $t("VALIDATION.MIN_VALUE", {
                      name: $t(
                        "PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE"
                      ),
                      min: $v.form.commission.$params.minValue.min
                    })
                  }}
                </div>
              </template>
            </div>
          </div>

          <!-- <div class="form-group row" v-if="showRepresentativeInput">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("COMPANY.SALES_REPRESENTATIVE") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <b-form-select
                v-model="$v.form.representative.$model"
                :options="salesRepresentatives"
                text-field="name"
                value-field="id"
              >
                <template #first>
                  <b-form-select-option :value="null">{{
                    $t("FORM.PLEASE_SELECT")
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div> -->

          <!-- domestic transfer -->
          <!-- <template v-if="showDomesticTransferForm">
            <p class="font-weight-bold offset-1 text-uppercase">
              {{ $t("COMPANY.domestic_transfer_infos") }}
            </p>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.bank") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.bankName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.account_name") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.accountName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.account_type") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.accountType"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                IBAN
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.iban"
                />
              </div>
            </div>

            <p class="font-weight-bold offset-1 text-uppercase mt-1">
              {{ $t("COMPANY.overseas_transfer_infos") }}
            </p>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.bank") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasBankName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.buyer") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasBuyerInfo"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.city") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasCityInfo"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.account_no") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasAccountNo"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                {{ $t("COMPANY.swift_code") }}
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasSwiftCode"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label text-right col-lg-3 col-sm-12">
                IBAN
              </label>
              <div class="col-lg-6 col-md-9 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="companyPaymentInformation.overseasIbanNo"
                />
              </div>
            </div>
          </template> -->
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-9 ml-lg-auto d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-success mr-5"
                :disabled="isSubmitting"
              >
                <span
                  v-if="isSubmitting"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ buttonTitle }}
              </button>
              <template
                v-if="
                  isDistributor && company && company.id && showDeleteButton
                "
              >
                <button
                  @click.stop.prevent="
                    $emit('delete-company', {
                      id: company.id,
                      isActive: company.isActive ? false : true
                    })
                  "
                  class="btn mr-2"
                  :class="[company.isActive ? 'btn-danger' : 'btn-success']"
                >
                  {{
                    company.isActive
                      ? $t("COMMON.RESTRICT_ACCESS")
                      : $t("COMMON.GRANT_ACCESS")
                  }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  numeric,
  minValue,
  requiredIf,
  maxLength
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// import Api from "@/services/api";
import CompanyDetails from "@/components/company/CompanyDetails.vue";

export default {
  mixins: [validationMixin],
  props: ["showDeleteButton", "isSubmitting"],
  computed: {
    ...mapGetters({
      selectedCompany: "company/SELECTED_COMPANY",
      activeUser: "activeUser",
      isBranch: "isBranch",
      isDistributor: "isDistributor",
      isMerchant: "isMerchant",
      mainCompany: "company/MAIN_COMPANY"
    }),
    getCompany() {
      let company;
      if (this.isRouteInSettings) {
        company = this.mainCompany;
      } else {
        company = this.selectedCompany;
      }
      return company;
    },
    isEdit() {
      if (this.isRouteInSettings) {
        return true;
      }
      // eslint-disable-next-line no-extra-boolean-cast
      return !!this.$route.params?.companyId ? true : false;
    },
    isRouteInSettings() {
      return this.$route.name === "settings-general";
    },
    showCompanyDetails() {
      return this.isBranch || (this.isMerchant && this.isRouteInSettings);
    },
    showCommissionInput() {
      return this.isDistributor && !this.isRouteInSettings;
    },
    showRepresentativeInput() {
      return this.isDistributor && !this.isRouteInSettings;
    },
    showDomesticTransferForm() {
      return !this.isEdit && this.isDistributor;
    }
  },
  components: { CompanyDetails },
  data() {
    return {
      company: {},
      companyUsers: [],
      form: {
        title: null,
        email: null,
        phone: null,
        taxNumber: "",
        taxAdministration: "",
        taxCity: "",
        hasQuickSale: false,
        commission: 0,
        representative: null,
        pos: null
      },
      isNew: true,
      buttonTitle: null,
      salesRepresentatives: [],
      companyPaymentInformation: {
        iban: null,
        bankName: null,
        accountName: null,
        accountType: null,
        overseasBuyerInfo: null,
        overseasBankName: null,
        overseasSwiftCode: null,
        overseasAccountNo: null,
        overseasCityInfo: null,
        overseasIbanNo: null
      }
    };
  },
  validations: {
    form: {
      title: { required },
      email: { required },
      phone: {
        numeric,
        required: requiredIf(function() {
          return this.isDistributor || this.isMerchant;
        }),
        maxLength: maxLength(10)
      },
      taxNumber: {},
      taxAdministration: {},
      taxCity: {},
      commission: {
        numeric,
        minValue: minValue(0)
      },
      hasQuickSale: {},
      representative: {},
      pos: {}
    }
  },
  watch: {
    selectedCompany(newCompany) {
      this.company = newCompany;
      this.setForm();
    }
  },
  async mounted() {
    this.company = this.getCompany;
    this.setForm();
  },
  methods: {
    async setForm() {
      const company = this.company;

      /* if (this.isDistributor) {
        const representativesData = await Api.get("representatives");
        this.salesRepresentatives =
          representativesData.data?.filter(
            representative => representative.isActive
          ) || [];
      } */

      /* if (company?.representativeID) {
        // const representative = await Api.get(company.representativeUri);
        this.form.representative = company.representativeID;
      } */

      if (this.isEdit) {
        this.isNew = false;
        this.buttonTitle = this.$t("FORM.UPDATE");
        this.form.title = company?.title || null;
        this.form.email = company?.email || null;
        this.form.phone = company?.phone || null;
        this.form.taxNumber = company?.taxNumber || "";
        this.form.taxAdministration = company?.taxAdministration || "";
        this.form.taxCity = company?.taxCity || "";
        this.form.hasQuickSale = company?.hasQuickSale || false;
        this.form.commission = company?.commission || 0;
      } else {
        this.buttonTitle = this.$t("FORM.SUBMIT");
        if (!this.isDistributor) {
          this.form.isActive = false;
        }
      }
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.form.hasQuickSale ??= false;
      delete this.form.pos;

      /* if (this.isDistributor) {
        this.form.representative = this.form?.representative
          ? this.salesRepresentatives.find(
              val => val.id === this.form.representative
            )
          : null;
      } */
      if (this.isMerchant && this.mainCompany) {
        this.form.commission =
          this.mainCompany?.commission > 0 ? this.mainCompany.commission : 0;
      }
      if (!this.isEdit && this.isDistributor) {
        this.form = {
          ...this.form,
          // companyPaymentInformation: this.companyPaymentInformation
          companyPaymentInformation: null
        };
      }
      this.$emit("submit", this.form);
    }
  }
};
</script>
