`<template>
  <div class="container">
    <div class="card card-custom example example-compact">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">
            {{ $t("COMPANY.TITLE") }}
          </label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.title ? company.title : "" }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">
            {{ $t("FORM.EMAIL") }}
          </label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.email ? company.email : "" }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">{{
            $t("COMPANY.TAX_NUMBER")
          }}</label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.taxNumber ? company.taxNumber : "" }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">{{
            $t("COMPANY.TAX_ADMINISTRATION")
          }}</label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.taxAdministration ? company.taxAdministration : "" }}
            </p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">{{
            $t("COMPANY.TAX_CITY")
          }}</label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.taxCity ? company.taxCity : "" }}
            </p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">{{
            $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
          }}</label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.commission ? company.commission : "" }}
            </p>
          </div>
        </div>

        <!-- <div class="form-group row">
          <label class="col-form-label text-right col-lg-3 col-sm-12">{{
            $t("COMPANY.SALES_REPRESENTATIVE")
          }}</label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <p class="form-control bg-secondary">
              {{ company.representativeName ? company.representativeName : "" }}
            </p>
          </div>
        </div> -->
      </div>
      <!-- <div class="card-footer" v-if="isMerchant">
        <div class="row">
          <div class="col-md-9 col-sm-12 d-flex justify-content-end">
            <b-button
              @click="$bvModal.show('update-payment-info-modal')"
              variant="success"
              class="font-weight-bold"
            >
              <b-icon icon="credit-card" aria-hidden="true"></b-icon>
              {{ $t("COMPANY.update_payment_info") }}
            </b-button>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <UpdatePaymentInfoModal /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UpdatePaymentInfoModal from "@/components/company/UpdatePaymentInfoModal.vue";

export default {
  name: "CompanyDetails",
  props: [],
  computed: {
    ...mapGetters({
      isBranch: "isBranch",
      isMerchant: "isMerchant",
      company: "company/MAIN_COMPANY"
    })
  },
  components: {
    /* UpdatePaymentInfoModal */
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
